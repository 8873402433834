import { Box, Image } from "@chakra-ui/react";
import LogoIcon from "@/assets/logo.svg";
import React, { createContext, useContext, useEffect } from "react";
import Do from "@/assets/do.svg";
const UserDataContext = createContext<{
  init: boolean;
  userData: any;
  onUserDataChange: (data: any) => void;
}>(null);

export const UserDataProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [init, setInit] = React.useState(false); // [1
  const [userData, setUserData] = React.useState({});

  const onUserDataChange = (data: any) => {
    setUserData(data);
  };

  const onFirstChange = () => {
    setInit(true);
  };

  useEffect(() => {
    setTimeout(() => {
      onFirstChange();
    }, 2000);
  }, []);

  return (
    <UserDataContext.Provider value={{ init, userData, onUserDataChange }}>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: init ? -10 : 100,
          transition: "all 0.5s",
          background: "#000",
          opacity: init ? 0 : 1,
        }}
      >
        <Box
          sx={{
            paddingTop: init ? "0" : "20px",
            position: "absolute",
            left: "50%",
            top: "140px",
            transform: "translate(-50%,0)",
            background: "#000",
            height: "100%",
          }}
        >
          <Image
            src={Do}
            sx={{
              width: "240px",
            }}
          ></Image>
        </Box>
      </Box>
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserData = () => {
  return useContext(UserDataContext);
};
