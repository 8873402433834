import axios from "axios";
// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoibXl1c2VybmFtZWFhIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbmFtZWlkZW50aWZpZXIiOiI1MDAwMjg0MDI2IiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9zZXJpYWxudW1iZXIiOiI4NjlmZDcxODU2ODQ0MzEyOTRhMjFjYTAyNDc0YTUyNyIsIm5iZiI6MTcyMzAwNjA0NywiZXhwIjoxNzIzMDkyNDQ3LCJpc3MiOiJvcnoudG9ucGVwZXMudGVzdC5Jc3N1ZXIiLCJhdWQiOiJvcnoudG9ucGVwZXMudGVzdC51c2VyIn0.TdZz0DLeGbfSHsL1B-KG5Ru8NbgjTMsOgSpr0qWeRok

export const api = axios.create({
  baseURL:
    process.env.REACT_APP_ENV == "production"
      ? "https://api.alpha-dogs.vip" //https://api.tonpepes.xyz/api
      : "https://api.alpha-dogs.vip",
});

export const getOwner = async () => {
  const response = await api.get("/owner", {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};
