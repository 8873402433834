import { css } from "@emotion/react";

export const GlobalStyles = css`
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: -apple-system, "SF Pro Text", "SF UI Text", system-ui,
      "Helvetica Neue", Helvetica, Arial, sans-serif, sans-serif;
  }
  html {
    height: 100%;
  }
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
  }
  #root {
    height: 100%;
  }
`;
